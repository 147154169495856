import React, { useEffect } from "react";
import { useAuth, actionTypes } from "./utils/auth/auth-context";
import { useLocation, useNavigate } from "react-router-dom";
import { getStoredAccessToken, getStoredCodeVerifier } from "./utils/utils";
import { authFetchAsync } from "utils/auth/authFetch";
let CRPTInitialLoad = true;

function SecuredApp(props: any) {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const storedAccessToken = getStoredAccessToken();

    useEffect(()=>{
        // To remove code from the url
        if(code && storedAccessToken) {
            setTimeout(()=>{
                navigate("/home", { replace: true });
            },200);
        }
    }, [code, storedAccessToken, navigate]);

    // Handle manual refresh
    if(CRPTInitialLoad) {
        const objPerformanceNavigationTiming: any = window.performance.getEntriesByType("navigation")[0] || {};
        if(objPerformanceNavigationTiming.type === "reload") {
            console.log("Silent Login...");
            CRPTInitialLoad = false;
            auth.handleManualRefresh();
            return null;
        }
    }

    // Handle code after Login (PKCE)
    if(code && storedAccessToken === null) {
        console.log("Redirected with code...");
        const codeVerifier = getStoredCodeVerifier();
        if(codeVerifier) {
            auth.handleCode(code, codeVerifier).then(async (isSuccessful:boolean)=>{
                if(isSuccessful) {
                    try {
                        const response = await authFetchAsync("api/Login/userlogin");
                        if (!response.ok) {
                            throw new Error("");
                        }
                    } catch (err) {
                        console.log("Failed to log Login activity");
                    }
                }
            });
        }
        return null;
    }

    // Initiate Login
    if(storedAccessToken === null) {
        console.log("Login initiated...");
        auth.dispatch({type: actionTypes.LOGIN});
        return null;
    }

    return (
        <>
            {props.children}
        </>
    );
}

export default SecuredApp;
