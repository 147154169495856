import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Nav, INavLink, INavStyles, INavLinkGroup } from "@fluentui/react/lib/Nav";
import { useTranslation } from "react-i18next";
import { IconButton } from "@fluentui/react/lib/Button";
import { useStoreState } from "utils/store"
import styles from "./NavBar.module.scss";

const navStyles: Partial<INavStyles> = {
    root: {
        width: 210
    }
};

const pages = {
    productgroupandrules: "/productgroupandrules",
    siteclusterandrules: "/siteclusterandrules",
    roundingrule: "/roundingrule",
    termdefinition: "/termdefinition",
    pricesetting: "/pricesetting",
    costcockpit: "/costcockpit"
}

const NavBar: React.FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const [selectedKey, setSelectedKey] = useState(location.pathname);
    const roles = useStoreState((state: any) => state.user?.roles);

    const refNavBar = useRef<any>();
    const refNavBarToggleButton = useRef<any>();

    useEffect(() => {
        setSelectedKey(location.pathname)
    }, [location])

    const isExpanded = (
        selectedKey.startsWith("/rules") || 
        selectedKey.startsWith(pages.productgroupandrules) || 
        selectedKey.startsWith(pages.siteclusterandrules) || 
        selectedKey.startsWith(pages.roundingrule) || 
        selectedKey.startsWith("/productlines") || 
        selectedKey.startsWith("/biggerpacklines") || 
        selectedKey.startsWith(pages.termdefinition)
    );

    const navLinkGroups: INavLinkGroup[] = [
        {
            links: [
                {
                    name: t("navbar.home"),
                    url: "/",
                    key: "/",
                    links: [],
                    isExpanded: false,
                    icon: "Home",
                    // iconProps: { imageProps: { src: "images/logo192.png", className: styles["icon"] }  }
                    roles: null // Everyone can see
                },
                {
                    name: t("navbar.configuration"),
                    // @ts-ignore
                    url: undefined,
                    roles: null,
                    isExpanded: isExpanded,
                    links: [
                        // {
                        //     name: t("navbar.rules"),
                        //     url: "/rules",
                        //     key: "/rules",
                        //     disabled: false,
                        //     icon: "WaitlistConfirm",
                        //     roles: [Role.PricingManager]
                        // },
                        {
                            name: t("navbar.termdefinition"),
                            url: pages.termdefinition,
                            key: pages.termdefinition,
                            disabled: false,
                            icon: "Dictionary",
                            roles: null
                        },
                        {
                            name: t("navbar.roundingrule"),
                            url: pages.roundingrule,
                            key: pages.roundingrule,
                            disabled: false,
                            icon: "WaitlistConfirm",
                            roles: null
                        },
                        {
                            name: t("navbar.productgroupandrules"),
                            url: pages.productgroupandrules,
                            key: pages.productgroupandrules,
                            disabled: false,
                            icon: "ProductList",
                            roles: null
                        },
                        {
                            name: t("navbar.siteclusterandrules"),
                            url: pages.siteclusterandrules,
                            key: pages.siteclusterandrules,
                            disabled: false,
                            icon: "DonutChart",
                            roles: null
                        },
                    ]
                },
                {
                    name: t("navbar.pricingandpromo"),
                    // @ts-ignore
                    url: undefined,
                    roles: null,
                    isExpanded: selectedKey.startsWith(pages.pricesetting) || selectedKey.startsWith(pages.costcockpit),
                    links: [
                        // {
                        //     name: "Cost Cockpit",
                        //     url: pages.costcockpit,
                        //     key: pages.costcockpit,
                        //     disabled: false,
                        //     icon: "AdminCLogoInverse32",
                        //     roles: null
                        // },
                        {
                            name: t("navbar.pricesetting"),
                            url: pages.pricesetting,
                            key: pages.pricesetting,
                            disabled: false,
                            icon: "AdminPLogoInverse32",
                            roles: null
                        },
                        {
                            name: "Promo Evaluation",
                            url: "https://app.powerbi.com/groups/me/apps/7d7136cf-b9e0-4c44-a646-f411786e2deb/reports/b0c278ed-5ab9-471b-9691-717ad2811fe5/ReportSection91558967853a7e40e1ba?ctid=db1e96a8-a3da-442a-930b-235cac24cd5c",
                            key: "promoEvaluationLink",
                            disabled: false,
                            icon: "Link",
                            roles: null,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                ev?.preventDefault();
                                window.open(item?.url, "_blank");
                            }
                        }
                    ]
                },
            ]
        }];

    const userNavLinkGroups = [{ links: navLinkGroups[0].links.filter(o => o.roles === null || roles.filter((r:any) => o.roles.includes(r)).length > 0) }];

    function onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        if (item?.url) {
            ev?.preventDefault();
            navigate(item?.url);
        }
    }

    function handleToggle() {
        refNavBar.current.classList.toggle("collapsed-navbar");
        refNavBarToggleButton.current.classList.toggle("collapsed-navbar");
    }

    return (
        <nav id="nav-menu" aria-label="Main navigation">
            <div
                id="nav-toggle"
                className="toggle-navbar-button"
                ref={refNavBarToggleButton}
                onClick={handleToggle}
                aria-controls="nav-menu"
            >
                <IconButton
                    className={styles.collapsedIcon}
                    iconProps={{ iconName: "ClosePaneMirrored" }}
                    title="Toggle Navbar"
                />
            </div>
            <div ref={refNavBar}>
                <Nav
                    onLinkClick={onLinkClick}
                    selectedKey={selectedKey}
                    styles={navStyles}
                    groups={userNavLinkGroups}
                    className={styles["navbar"]}
                />
            </div>
        </nav >
    )
};

export default NavBar;

