import React from "react";
import { Link } from "react-router-dom";
import UserMenu from "./UserMenu";
import { useTranslation } from "react-i18next";
import styles from "./Header.module.scss";

const Header: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return <header>
        <div className={styles["header"]}>
            <div className={styles["left"]}>
                <Link to="/" className={styles["link"]} >
                    <img src="/images/logo192.png" className={styles["logo"]} alt="Shell Logo" />
                </Link>
                <div className={styles["title"]}>{t("common.title")}</div>
            </div>
            <div className={styles["right"]}>
                <UserMenu />
            </div>
        </div>
    </header>
};

export default Header;

