import { configureStore } from "@reduxjs/toolkit";
import roundingRuleReducer from "./features/roundingRule/roundingRuleSlice";
import multiMarketReducer from "./features/multiMarket/multiMarketSlice";
import appReducer from "./features/app/appSlice";

const store = configureStore({
  reducer: {
    roundingRule: roundingRuleReducer,
    multiMarket: multiMarketReducer,
    app: appReducer,
  }
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {roundingRule: RoundingRuleState}
export type AppDispatch = typeof store.dispatch