import { authFetchAsync } from "utils/auth/authFetch";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { storageKey } from "types/common";

const defaultMarket = localStorage.getItem(storageKey.CRPTSelectedMarket) || "";

type responceMarket = {
    countryId: number,
    countryCd: string,
    countryName: string,
    countryDesc: string|null,
    countryStatus: boolean
}

type MarketData = {
    markets: string[],
    selectedMarket: string
}

type InitialState = {
    loading: boolean,
    error: string,
    marketData: MarketData
}

const initialState: InitialState = {
    loading: true,
    error: "",
    marketData: {
        markets: [],
        selectedMarket: ""
    }
}

// Generates pending, fulfilled and rejected action types
export const fetchMarkets = createAsyncThunk(
    "system/fetchMarkets",
    async () => {
        // TODO: Change API
        return authFetchAsync("api/Country/usercountries","GET")
            .then(response => response.json())
            .then((data) => {

                data = data.map((objCountry: responceMarket) => {
                    return objCountry.countryCd;
                })

                return {
                    markets: data,
                    selectedMarket: data.includes(defaultMarket)? defaultMarket : data[0]
                }
            });
    }
);

const multiMarketSlice = createSlice({
    name: "multiMarket",
    initialState,
    reducers: {
        saveMarketSelection: (state, action: PayloadAction<string>) => {
            const newSelection = action.payload;
            state.marketData.selectedMarket = newSelection;
            localStorage.setItem(storageKey.CRPTSelectedMarket, newSelection);
        }
    },
    extraReducers: (builder) => {
        // Fetching Markets
        builder.addCase(fetchMarkets.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchMarkets.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || "Something went wrong";
        });
        builder.addCase(
            fetchMarkets.fulfilled,
            (state: InitialState, action: any) => {
                state.loading = false;
                state.error = "";
                state.marketData = action.payload || initialState.marketData;
            }
        );
    }
})

export default multiMarketSlice.reducer;
export const { saveMarketSelection } = multiMarketSlice.actions
