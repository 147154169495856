import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory, BrowserHistoryOptions } from "history";

declare global {
    interface Window {
        ENV: any;
    }
}

const browserHistory = createBrowserHistory({ basename: "" } as BrowserHistoryOptions);
const appInsightsReactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: window.ENV?.appInsightsKey,
        extensions: [appInsightsReactPlugin],
        extensionConfig: {
            [appInsightsReactPlugin.identifier]: { history: browserHistory }
        }
    }
});
if (window.ENV?.AppInsightsKey) {
    appInsights.loadAppInsights();
}

export { appInsightsReactPlugin, appInsights };