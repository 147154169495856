import Header from "components/Header/Header";
import NavBar from "components/NavBar/NavBar";
import styles from "./PageLayout.module.scss";
import { ThemeProvider, PartialTheme } from "@fluentui/react";

const appTheme: PartialTheme = {
    palette: {
        themePrimary: "#D01B1E", // red #dd1d21
        themeLighterAlt: "#fef5f5",
        themeLighter: "#fad7d8",
        themeLight: "#f5b5b6",
        themeTertiary: "#009EB4",   // shell-mid-blue
        themeSecondary: "#FBCE07", // yellow
        themeDarkAlt: "#c81a1d",
        themeDark: "#a91618",
        themeDarker: "#7c1012",
        neutralLighterAlt: "#faf9f8",
        neutralLighter: "#f3f2f1",
        neutralLight: "#edebe9",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c6c4",
        neutralTertiary: "#a19f9d",
        neutralSecondary: "#605e5c",
        neutralPrimaryAlt: "#3b3a39",
        neutralPrimary: "#323130",
        neutralDark: "#201f1e",
        black: "#000000",
        white: "#ffffff",
    },
    defaultFontStyle: { fontFamily: "Segoe UI, Roboto, Helvetica, Sans-Serif", fontWeight: "regular" },
}

export const PageLayout: React.FunctionComponent<any> = (props) => {
    return <ThemeProvider theme={appTheme}>
        <div className={styles["layout"]}>
            <Header />
            <div className={styles["main"]}>
                <NavBar />
                <div className={styles["content"]}>{props.children}</div>
            </div>
        </div>
    </ThemeProvider>
};
