import sha256 from "crypto-js/sha256";
import qs from "qs";
import base64 from "crypto-js/enc-base64";

/**
 * This service will help the use log into the system depending on what kind of user they are.
 */
class LoginService {
  CODE_VERIFIER = "";
  CODE_CHALLENGE = "";
  ACCESS_TOKEN = "";
  REFRESH_TOKEN = "";
  ID_TOKEN = "";

  PING = {
    baseUrl: window.ENV.authBaseUrl,
    redirectUrl: window.ENV.redirectUrl,
    clientId: window.ENV.newClientId,
    responseType: "code",
    codeChallengeMethod: "S256",
    authorizationGrantType: "authorization_code"
  };

  /**
   * This function Generates a code verifier which is a random string of 43 chars, it will be used for the PKCE login flow.
   * @param   {number} length - The length of the string
   */
  generateCodeVerifier(length: number) {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * This function Generates a code challenge corrosponding to the code verifier. It is used in the PKCE login flow.
   */
  generateCodeChallenge() {
    this.CODE_VERIFIER = this.generateCodeVerifier(43);
    return this.base64URL(sha256(this.CODE_VERIFIER));
  }

  /**
   * A utility function that encodes the verifier to base 64 encoding.
   * @param   {string} verifier  - The code verifier that needs to be encoded.
   */
  base64URL(verifier: any) {
    return verifier
      .toString(base64)
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  }

  /**
   * A function that helps the shell user log into the system by redirecting them to the PING's login portal.
   * This is a PKCE flow, to learn more about it check out this video. https://youtu.be/yf2Hge3VHKY
   */
  shellUserLogin() {
    this.CODE_CHALLENGE = this.generateCodeChallenge();
    const {
      baseUrl,
      clientId,
      redirectUrl,
      responseType,
      codeChallengeMethod,
      authorizationGrantType,
    } = this.PING;

    const loginPayload = {
      loginType: "shell",
      codeVerifier: this.CODE_VERIFIER,
    };
    localStorage.setItem("loginPayload", JSON.stringify(loginPayload));

    const URL = `${baseUrl}/as/authorization.oauth2?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=${responseType}&code_challenge=${this.CODE_CHALLENGE}&code_challenge_method=${codeChallengeMethod}&grant_type=${authorizationGrantType}`;
    window.location.replace(URL);
  }

  /**
   * This function continues the login flow after redirection.
   * i.e When the user is redirected back to the website after they've logged into PING.
   */
  async handelUserAfterPINGRedirection(code: string, codeVerifier: string) {
    if (!code || !codeVerifier) {
      return null;
    }
    await this.tryFetchShellToken(code, codeVerifier);
    return {
      accessToken: this.ACCESS_TOKEN || "",
      refreshToken: this.REFRESH_TOKEN || ""
    }
  }

  /**
   * A function that exchanges the auth code with PING's server to get a JWT token.
   * @param {string} code  - The auth code from you get in query params, after redirection.
   * @param {string} codeVerifier  - The code verifier that got generated when user initiated this login.
   */
  async tryFetchShellToken(code: string, codeVerifier: string) {
    const { clientId, redirectUrl, authorizationGrantType, baseUrl } =
      this.PING;

    const payload = {
      client_id: clientId,
      code: code,
      code_verifier: codeVerifier,
      redirect_uri: redirectUrl,
      grant_type: authorizationGrantType,
    };

    const tokenUrl = `${baseUrl}/as/token.oauth2`;

    try {
      const response = await fetch(tokenUrl, {
        method: "post",
        headers: new Headers({
            "Content-Type": "application/x-www-form-urlencoded",
        }),
        body: qs.stringify(payload),
      });
      const data = await response?.json();
      
      if (response && data) {
        this.ACCESS_TOKEN = data.access_token;
        this.REFRESH_TOKEN = data.refresh_token;
        return {
          accessToken: this.ACCESS_TOKEN,
          refreshToken: this.REFRESH_TOKEN
        };
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  }

  async initiateSilentRefreshOfPingToken(refreshToken: string) {
      const { clientId, baseUrl } = this.PING;
      const payload = {
        client_id: clientId,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      };
      const pingTokenUrl = `${baseUrl}/as/token.oauth2`;

      const response = await fetch(pingTokenUrl, {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
        body: qs.stringify(payload),
      });
      const data = await response.json();

      if(data.access_token) {
        this.REFRESH_TOKEN = data.refresh_token;
        this.ACCESS_TOKEN = data.access_token;
        return {
          accessToken: this.ACCESS_TOKEN,
          refreshToken: this.REFRESH_TOKEN
        };
      }
  }

  shellUserLogout() {
    const { clientId, baseUrl } = this.PING;

    revokeToken(clientId, this.ACCESS_TOKEN, "access_token");
    revokeToken(clientId, this.REFRESH_TOKEN, "refresh_token");
    async function revokeToken(clientId:any, token:string, tokenTypeHint:string) {
      const revocationEndpoint = `${baseUrl}/as/revoke_token.oauth2`;
      const clientSecret = "";
    
      await fetch(revocationEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": `Basic ${window.btoa(`${clientId}:${clientSecret}`)}`
        },
        body: `token=${token}&token_type_hint=${tokenTypeHint}`
      });
    }

    sessionStorage.clear();
  }
}

const loginService = new LoginService();
export { loginService };
