import { createStore, action, Action, createTypedHooks } from "easy-peasy"

interface User {
    roles: Array<string>;
    username?: string;
    setRoles: Action<User, string[]>;
    setUsername: Action<User, string>;
}

export interface StoreModel {
    user: User
}

// same as 
// const store = createStore(model);
// export default store;
export default createStore<StoreModel>({
    user: {
        roles: [],
        username: undefined,
        setRoles: action((state, payload) => {
            state.roles = payload
        }),
        setUsername: action((state, payload) => {
            state.username = payload
        })
    }
})

// In order to avoid having to constantly provide your StoreModel definition to each use of the Easy Peasy hooks:
const typedHooks = createTypedHooks<StoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;
