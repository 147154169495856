import { IColumn } from "@fluentui/react/lib/DetailsList";
import { authFetchAsync } from "utils/auth/authFetch";
import { formatDecimal, formatWithRoundingRule } from "./format";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { cloneDeep } from "lodash";
import { ILookup, PriceDeltaType, ProductGroupType } from "types/lookups";
import { storageKey } from "types/common";
import { IProductGroupResponseData } from "pages/ProductGroupAndRules/ProductGroupAndRules";
import { RoundingRuleConfig } from "store/features/roundingRule/roundingRuleSlice";

export const defaultHiddenPSColumns = ["unitsoldytd","manufacturer","supplier"];
export const defaultHiddenCCColumns = [];

export interface ICountryFormulas {
    calcUmgnNew: (tax: number, rrpNew: number, costNew: number, decimalPoint: number) => [number, number, number];
    calcUmgnPerNew: (tax: number, rrpNew: number, rawUpdatedUmgnNew: number) => number;
    calcRrpChange: (rrpNew: number, rrpCur: number, decimalPoint: number) => number|null;
    calcRrpChangePercent: (rrpNew: number, rrpCur: number) => number|null;
    calcTotMgnRoY: (rawUpdatedUmgnNew: number, unitSoldFcstRoY: number|null) => number|null;
    calcTotMgnChangeFY: (rawUpdatedUmgnNew: number, unitSoldFcstFY: number|null, uMgnDoNthg: number|null) => number|null;
    calcBreakevenPercent: (rawUpdatedUmgnNew: number, umgnCur: number|null, decimalPoint: number) => number|null;

    calcProposedCostToSite: (proposedCostFromSupplier: number, value: number, decimalPoint: number) => number;
    calcProposedRRP: (value: number, umgnCur: number, uMgnPercentCUR: number, tMrgnPrcntg:number, tax: number, roundingRuleConfig: RoundingRuleConfig) => number;
    calcUmgnPercent: (rawUpdatedProposedRRP: number, tax: number, value: number, rrpCurrUqty: number) => number;
    calcProposedCostNew: (value: number, decimalPoint: number) => number;
}

export function arraysHaveSameContent(array1?: Array<any>, array2?: Array<any>) {
    if (array1 === undefined) {
        array1 = [];
    }
    if (array2 === undefined) {
        array1 = [];
    }
    return array1.length === array2!.length && array1.every((value, index) => value === array2![index])
}

export function dynamicSort(property: string, isDescending: boolean = false) {
    var sortOrder = isDescending ? -1 : 1;
    return function (a: any, b: any) {
        /* next line works with strings and numbers */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export function createNewColumnsSortConfig(initialColumns: IColumn[], sortByColField: string, dontToggleAndSetIsSortedDescending?: boolean) {
    const newColumns: IColumn[] = initialColumns.slice(); // Copy
    const currColumn: IColumn = newColumns.filter(currCol => currCol.fieldName === sortByColField)[0];
    newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
            currColumn.isSortedDescending = (dontToggleAndSetIsSortedDescending === undefined) ?
                currColumn.isSorted && !currColumn.isSortedDescending :
                dontToggleAndSetIsSortedDescending;
            currColumn.isSorted = true;
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = false;
        }
    });
    return { columns: newColumns, orderBy: `${currColumn?.fieldName}${currColumn?.isSortedDescending ? " desc" : ""}` };
}


// Function for calculating new tick interval value for an axis so that ticks are aligned on all axis
export function calculateStepSize(intervals: number, max: number) {
    let step = Math.ceil(max) / intervals;

    // For values < 10 round to first decimal place  
    if (max < 10) {
        step = Math.ceil(max * 10 / intervals) / 10;
    }
    else {
        for (var pow = 6; pow > 0; pow--) {
            if (max >= 10 ** pow) {
                const minUnit = 10 ** (pow - 1);
                // Round up to nearest 10^(pow-1) - it should be a multiple of intervals
                step = Math.ceil(step / minUnit) * minUnit;
                break;
            }
        }
    }
    // console.log("step size for ", max, step)
    return step;
}

export async function loadDropdownOptionsAsync(url: string): Promise<IDropdownOption[]> {
    const response = await authFetchAsync(url);
    if (response.ok) {
        const newData = await response.json();
        const options = newData.map((o: ILookup) => {
            return { key: o.id, text: o.name }
        });
        // Add empty entry on top
        options.splice(0, 0, { key: 0, text: "" });
        return options;
    }
    return [];
}

export function distinct<T>(array: Array<T>): Array<T> {
    return Array.from(new Set(array))
}

export function getCalculatedValue(
        value:number,
        delta:string|undefined,
        decimalPoint = "2",
        leaderCapacity:null|number = 1,
        leaderUnit:null|number = 1,
        memberCapacity:null|number = 1,
        memberUnit:number = 1,
        packQty:null|number = 1,
        productGroupType: null | keyof typeof ProductGroupType,
        deltaType: null | keyof typeof PriceDeltaType,
    ) {
    let calculatedValue = value || 0;
    const [deltaOperation, deltaValue] = delta?.split(" ") as [string, string] || ["","0"];
    let deltaNumericValue = Number((deltaOperation === "minus") ? -deltaValue : deltaValue);

    switch (productGroupType) {
        case null:
        case ProductGroupType.Standard:
            handleCalculation();
            break;
        case ProductGroupType.BPBV:
            handleBPBVProduct();
            break;
    }
    function handleCalculation() {
        if (deltaType === PriceDeltaType.Percentage) {
            // deltaNumericValue = calculatedValue * (deltaNumericValue / 100);

            calculatedValue = calculatedValue * deltaNumericValue;
            return;
        }
        calculatedValue = calculatedValue + (deltaNumericValue);
    }
    function handleBPBVProduct() {
        packQty = memberUnit === packQty ? 1 : packQty; // Change due to product grouping page: editable qty
        calculatedValue = (calculatedValue / (leaderCapacity! / leaderUnit!)) * memberCapacity! * memberUnit * packQty!;
        handleCalculation();
    }

    return calculatedValue.toFixed(Number(decimalPoint));
}

export function buildProductGroups(data: IProductGroupResponseData, filterText: string) {
    //TODO: revisit to optimize logic

    if (data.items.length === 0) {
        return {
            items: [],
            groups: []
        }
    }
    type TObj = {
        id: number,
        name: string
    }
    const sections: TObj[] = [];
    const groups: TObj[][] = [];
    const groupItems: TObj[][][] = [];

    const newData: IProductGroupResponseData = cloneDeep(data);

    // 1: Filter out Items
    if( filterText ) {
        newData.items = newData.items.filter(o => {
            const groupItemName = o.groupItemName.toLowerCase();
            return groupItemName.includes(filterText.toLowerCase());
        });
    }

    // 2: Data Sorting
    newData.items.sort((a,b) => a.sectionId - b.sectionId);
    newData.items.sort((a,b) => a.groupId - b.groupId);

    // 3: Data formation
    newData.items.forEach((item) => {
        const { sectionId, sectionName, groupId, groupName, groupItemId, groupItemName } = item;

        // sections: [{id: 1, name: "Netherlands"}]
        if(sections.findIndex(i=>i.id === sectionId) === -1) {
            sections.push({
                id: sectionId,
                name: sectionName
            });
        }

        // groups: [[{id:1, name: "NAB"}, {id:2, name: "NAB2"}]]
        const sectionIndex: number = sections.findIndex(i=>i.id === sectionId);
        if(!groups[sectionIndex]) {
            groups.push([{id: groupId, name: groupName}]);
        } else if(groups[sectionIndex].findIndex(i=>i.id === groupId) === -1) {
            groups[sectionIndex].push({id: groupId,name: groupName});
        }

        // groupItems: [[[{id: 1, name: "Coke Substitute"}, {}],[{}, {}]]]
        const groupIndex: number = groups[sectionIndex].findIndex(i=>i.id === groupId);
        if(!groupItems[sectionIndex]) {
            groupItems.push([[{id: groupItemId, name: groupItemName}]]);
        } else if(!groupItems[sectionIndex][groupIndex]) {
            groupItems[sectionIndex].push([{id: groupItemId, name: groupItemName}]);
        } else {
            groupItems[sectionIndex][groupIndex].push({id: groupItemId, name: groupItemName});
        }      
    });

    // 4: Data grouping
    let sectionStartIndex = 0;
    let groupItemStartIndex = 0;
    const formedGroupItems = groupItems.map((_sections, sectionIndex) => {
        const childGroups = {
            count: _sections.length,
            key: `section-${sections[sectionIndex].id}`,
            name: sections[sectionIndex].name,
            startIndex: sectionStartIndex,
            level: 0,
            children: _sections.map((_groups, groupIndex) => {
                const childGroups = {
                    count: _groups.length,
                    key: `group-${groups[sectionIndex][groupIndex].id}`,
                    name: groups[sectionIndex][groupIndex].name,
                    startIndex: groupItemStartIndex,
                    level: 1,
                    children: []
                }
                groupItemStartIndex = groupItemStartIndex + _groups.length;
                return childGroups;
            })
        }
        sectionStartIndex = sectionStartIndex + _sections.length;
        return childGroups;
    });

    newData.groups = formedGroupItems;

    return newData;
}

// export function buildClusterHierarchy(data: IHierarchyData, filterText: string) {
//     //TODO: revisit to optimize logic
//     // [{
//     //     pricingStrategyName: string,
//     //     pricingStrategyGroupId: number,
//     //     pricingStrategyGroupName: string,
//     //     countryCode: string,
//     //     disabled?: boolean
//     // }]

//     if (!data) {
//         return null;
//     }
//     type TObj = {
//         id: number,
//         name: string
//     }
//     const countries: TObj[] = [];
//     const itemGroups: TObj[][] = [];
//     const listItems: TObj[][][] = [];

//     const newData: IHierarchyData = cloneDeep(data);

//     // 1: Filter out Items
//     if( filterText ) {
//         newData.items = newData.items.filter(o => {
//             const listItemName = o.pricingStrategyName.toLowerCase();
//             return listItemName.includes(filterText.toLowerCase());
//         });
//     }

//     // 2: Data Sorting
//     newData.items.sort((a,b) => (a.countryCode > b.countryCode) ? 1 : ((b.countryCode > a.countryCode) ? -1 : 0));
//     newData.items.sort((a,b) => a.pricingStrategyGroupId - b.pricingStrategyGroupId);

//     // 3: Data formation
//     newData.items.forEach((item) => {
//         const { countryCode, pricingStrategyGroupId, pricingStrategyGroupName, pricingStrategyName }: any = item as any;

//         // countries: [{id: 1, name: "Netherlands"}]
//         if(countries.findIndex(i=>i.id === countryCode) === -1) {
//             countries.push({
//                 id: countryCode,
//                 name: countryCode
//             });
//         }

//         // strategy groups: [[{id:1, name: "Default"}, {id:2, name: "NAB Test"}]]
//         const countryIndex: number = countries.findIndex(i=>i.id === countryCode);
//         if(!itemGroups[countryIndex]) {
//             itemGroups.push([{id: pricingStrategyGroupId, name: pricingStrategyGroupName}]);
//         } else if(itemGroups[countryIndex].findIndex(i=>i.id === pricingStrategyGroupId) === -1) {
//             itemGroups[countryIndex].push({id: pricingStrategyGroupId, name: pricingStrategyGroupName});
//         }

//         // listItems: [[[{id: 1, name: "Berchem"}, {}],[{}, {}]]]
//         const strategyGroupIndex: number = itemGroups[countryIndex].findIndex(i=>i.id === pricingStrategyGroupId);
//         if(!listItems[countryIndex]) {
//             listItems.push([[{id: pricingStrategyName, name: pricingStrategyName}]]);
//         } else if(!listItems[countryIndex][strategyGroupIndex]) {
//             listItems[countryIndex].push([{id: pricingStrategyName, name: pricingStrategyName}]);
//         } else {
//             listItems[countryIndex][strategyGroupIndex].push({id: pricingStrategyName, name: pricingStrategyName});
//         }      
//     });

//     // 4: Data grouping
//     let countryStartIndex = 0;
//     let groupStartIndex = 0;
//     const formedGroups = listItems.map((_countries, countryIndex) => {
//         const childItemGroups = {
//             count: _countries.length,
//             key: `country-${countries[countryIndex].id}`,
//             name: countries[countryIndex].name,
//             startIndex: countryStartIndex,
//             level: 0,
//             children: _countries.map((_itemGroups, strategyGroupIndex) => {

//                 const id = Number(itemGroups[countryIndex][strategyGroupIndex].id);
//                 const key = `${id === 0 ? "disabled-" : "group-"}${id}`

//                 const childGroups = {
//                     count: _itemGroups.length,
//                     key: key,
//                     name: itemGroups[countryIndex][strategyGroupIndex].name,
//                     startIndex: groupStartIndex,
//                     level: 1,
//                     children: []
//                 }
//                 groupStartIndex = groupStartIndex + _itemGroups.length;
//                 return childGroups;
//             })
//         }
//         countryStartIndex = countryStartIndex + _countries.length;
//         return childItemGroups;
//     });

//     newData.groups = formedGroups;

//     return newData;
// }

export function getStoredAccessToken() {
    let objSessionTokens;
    const sessionTokens = sessionStorage.getItem("sessionTokens");
    if (sessionTokens) {
        objSessionTokens = JSON.parse(sessionTokens);
    }
    return objSessionTokens?.accessToken || null;
}

export function getStoredRefreshToken() {
    let objSessionTokens;
    const sessionTokens = sessionStorage.getItem("sessionTokens");
    if (sessionTokens) {
        objSessionTokens = JSON.parse(sessionTokens);
    }
    return objSessionTokens?.refreshToken || null;
}

export function getStoredCodeVerifier() {
    let objLoginPayload;
    const loginPayload = localStorage.getItem("loginPayload");
    if (loginPayload) {
        objLoginPayload = JSON.parse(loginPayload);
    }
    return objLoginPayload?.codeVerifier;
}

export function getCollapsedColumns() {
    let collapsedColumns = [];
    const lastSelection = localStorage.getItem(storageKey.PSHiddenColumns);
    if (lastSelection) {
        collapsedColumns = JSON.parse(lastSelection);
    }
    return collapsedColumns;
}

export function setDefaultCollapsedColumns() {
    const collapsedColumns = Array.from(
        new Set([
            ...getCollapsedColumns(),
            ...defaultHiddenPSColumns
        ])
    );

    localStorage.setItem(storageKey.PSHiddenColumns, JSON.stringify(collapsedColumns));
}

export function getCountryFormula(countryCode: string): ICountryFormulas {
    switch (countryCode) {
        case "PL":
            return {
                calcUmgnNew: (tax, rrpNew, costNew, decimalPoint) => {
                    const umgnNew = rrpNew/(1+tax/100)-costNew;
                    return [Number(formatDecimal(umgnNew, decimalPoint)), umgnNew, umgnNew];
                },
                calcUmgnPerNew: (tax, rrpNew, rawUpdatedUmgnNew) => {
                    return Number(formatDecimal((rawUpdatedUmgnNew / (rrpNew / (1+tax/100)) *100), 1));
                },
                calcRrpChange: (rrpNew, rrpCur, decimalPoint) => {
                    // RRP NEW - RRP CUR
                    return Number(formatDecimal(rrpNew - rrpCur, decimalPoint));
                },
                calcRrpChangePercent: (rrpNew, rrpCur) => {
                    // ((RRP NEW - RRP CUR) / RRP CUR) * 100
                    const result = ((rrpNew - rrpCur) / rrpCur) * 100;
                    return Number(formatDecimal(result, 1));
                },
                calcTotMgnRoY: (rawUpdatedUmgnNew, unitSoldFcstRoY) => {
                    // UMgn NEW * Unit Sold Fcst RoY
                    const result = rawUpdatedUmgnNew * (unitSoldFcstRoY||0)
                    return result;
                },
                calcTotMgnChangeFY: (rawUpdatedUmgnNew, unitSoldFcstFY, uMgnDoNthg) => {
                    // Unit Sold Fcst FY * (UMgn NEW - UMgn DoNthg)
                    const result = (unitSoldFcstFY||0) * (rawUpdatedUmgnNew - (uMgnDoNthg||0));
                    return result;
                },
                calcBreakevenPercent: (rawUpdatedUmgnNew, umgnCur, decimalPoint) => {
                    // (UMgn NEW - UMgn CUR) / UMgn NEW * 100
                    const result = 	(rawUpdatedUmgnNew - (umgnCur||0)) / rawUpdatedUmgnNew * 100;
                    return Number(formatDecimal(result, decimalPoint));
                },
                calcProposedCostToSite: (proposedCostFromSupplier, value, decimalPoint) => {
                    const result = 	(proposedCostFromSupplier + (value));
                    return Number(formatDecimal(result, decimalPoint));
                },
                calcProposedRRP: (value, umgnCur, uMgnPercentCUR, tMrgnPrcntg, tax, roundingRuleConfig) => {
                    const result = 	value*(1+(tax/100))/(1-(uMgnPercentCUR/100));
                    return Number(formatWithRoundingRule(result, roundingRuleConfig));
                },
                calcUmgnPercent: (rawUpdatedProposedRRP, tax, value, rrpCurrUqty) => {
                    const umgnNew = rawUpdatedProposedRRP/(1+tax/100)-value;
                    return Number(formatDecimal(((umgnNew/rawUpdatedProposedRRP)*100), 1));
                },
                calcProposedCostNew: (value, decimalPoint) => {
                    return Number(formatDecimal(value, decimalPoint));
                }
            };
        default:
            return {
                calcUmgnNew: (tax, rrpNew, costNew, decimalPoint) => {
                    const umgnNewForPer = (rrpNew/(1+(tax/100))-costNew) / (rrpNew/(1+(tax/100)));
                    const umgnNew = rrpNew/(1+(tax/100))-costNew;
                    return [Number(formatDecimal(umgnNew, decimalPoint)), umgnNew, umgnNewForPer];
                },
                calcUmgnPerNew: (tax, rrpNew, rawUpdatedUmgnNewForPer) => {
                    return Number(formatDecimal(rawUpdatedUmgnNewForPer*100, 1));
                },
                calcRrpChange: (rrpNew, rrpCur, decimalPoint) => {
                    // RRP NEW - RRP CUR
                    return Number(formatDecimal(rrpNew - rrpCur, decimalPoint));
                },
                calcRrpChangePercent: (rrpNew, rrpCur) => {
                    // ((RRP NEW - RRP CUR) / RRP CUR) * 100
                    const result = ((rrpNew - rrpCur) / rrpCur) * 100;
                    return Number(formatDecimal(result, 1));
                },
                calcTotMgnRoY: (rawUpdatedUmgnNew, unitSoldFcstRoY) => {
                    // UMgn NEW * Unit Sold Fcst RoY
                    const result = rawUpdatedUmgnNew * (unitSoldFcstRoY||0)
                    return result;
                },
                calcTotMgnChangeFY: (rawUpdatedUmgnNew, unitSoldFcstFY, uMgnDoNthg) => {
                    // Unit Sold Fcst FY * (UMgn NEW - UMgn DoNthg)
                    const result = (unitSoldFcstFY||0) * (rawUpdatedUmgnNew - (uMgnDoNthg||0));
                    return result;
                },
                calcBreakevenPercent: (rawUpdatedUmgnNew, umgnCur, decimalPoint) => {
                    // (UMgn NEW - UMgn CUR) / UMgn NEW * 100
                    const result = 	(rawUpdatedUmgnNew - (umgnCur||0)) / rawUpdatedUmgnNew * 100;
                    return Number(formatDecimal(result, decimalPoint));
                },
                calcProposedCostToSite: (proposedCostFromSupplier, value, decimalPoint) => {
                    const result = 	(proposedCostFromSupplier + (value));
                    return Number(formatDecimal(result, decimalPoint));
                },
                calcProposedRRP: (value, umgnCur, uMgnPercentCUR, tMrgnPrcntg, tax, roundingRuleConfig) => {
                    // const result = 	(value+umgnCur)*(1+(tax/100)); // Old
                    const result = 	(value/(100-tMrgnPrcntg))*(100+tax);
                    return Number(formatWithRoundingRule(result, roundingRuleConfig));
                },
                calcUmgnPercent: (rawUpdatedProposedRRP, tax, value, rrpCurrUqty) => {
                    const umgnNew = (rawUpdatedProposedRRP/(1+(tax/100))-value) / (rawUpdatedProposedRRP/(1+(tax/100)));
                    return Number(formatDecimal(umgnNew*100, 1));
                },
                calcProposedCostNew: (value, decimalPoint) => {
                    return Number(formatDecimal(value, decimalPoint));
                }
            };
    }
}

export function handleRowSelection(e:any) {
    // Select row with keyboard 'enter' kay
    if(e.keyCode === 13) {
        const chkBox = e.currentTarget?.querySelector(".ms-DetailsRow-cellCheck");
        chkBox?.click();
    }
}
