import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
    isLoggedIn: boolean
}

const initialState: InitialState = {
    isLoggedIn: true
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setLogout: (state) => {
            state.isLoggedIn = false;
        }
    }
})

export default appSlice.reducer;
export const { setLogout } = appSlice.actions
