import React, { Suspense, useMemo, useEffect } from "react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Routes, Route } from "react-router-dom";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { appInsightsReactPlugin } from "utils/init/appInsights";
import { PageLayout } from "./components/PageLayout/PageLayout";
import { NoUserAccess } from "./components/NoUserAccess/NoUserAccess";
import { StoreProvider } from "easy-peasy"
import store from "utils/store"
import { Logout } from "./pages/Logout/Logout";
import { setDefaultCollapsedColumns } from "utils/utils";

import { useAppDispatch, useAppSelector } from "store/typedHooks";
import { fetchRoundingRuleConfig } from "store/features/roundingRule/roundingRuleSlice";
import { fetchMarkets } from "store/features/multiMarket/multiMarketSlice";
import { storageKey } from "types/common";
import "./sass/global.scss";
import InactivityTracker from "./InactivityTracker";

const Home = React.lazy(() => import("./pages/Home/Home"));
// const CostCockpit = React.lazy(() => import("./pages/CostCockpit/CostCockpit"));
const PriceSetting = React.lazy(() => import("./pages/PriceSetting/PriceSetting"));
const RoundingRule = React.lazy(() => import("./pages/RoundingRule/RoundingRule"));
const ProductGroupAndRules = React.lazy(() => import("./pages/ProductGroupAndRules/ProductGroupAndRules"));
const SiteClusterAndRules = React.lazy(() => import("./pages/SiteClusterAndRules/SiteClusterAndRules"));
const TermDefinition = React.lazy(() => import("./pages/TermDefinition/TermDefinition"));

if(localStorage.getItem(storageKey.PSOrderedColumns) === null) {
    setDefaultCollapsedColumns();
}

export const App: React.FunctionComponent = () => {
    const selectedMarket = useAppSelector(state => state.multiMarket.marketData.selectedMarket);
    const isLoggedIn = useAppSelector(state => state.app.isLoggedIn);
    const dispatch = useAppDispatch();
    useMemo(() => {
        dispatch(fetchMarkets())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(selectedMarket) {
            dispatch(fetchRoundingRuleConfig());
        }
    }, [dispatch, selectedMarket])

    // Temp
    const isAuthenticated = true;

    if(selectedMarket === undefined) {
        return <NoUserAccess />;
    }

    if(!selectedMarket) {
        return null;
    }

    return (
        <Suspense fallback={<Spinner size={SpinnerSize.large} />}>
            <AppInsightsContext.Provider value={appInsightsReactPlugin}>
                <StoreProvider store={store}>
                    {isLoggedIn && <InactivityTracker />}
                    <PageLayout key={selectedMarket}>
                        {isAuthenticated ? <AppRoutes /> : <PublicRoutes />}
                    </PageLayout>
                </StoreProvider>
            </AppInsightsContext.Provider>
        </Suspense>
    )
}

function AppRoutes() {
    return (
        <React.Suspense fallback={<p>Loading...</p>}>
            <Routes>
                <Route path="/costcockpit" element={null} />
                {/* <Route path="/costcockpit" element={<CostCockpit />} /> */}
                <Route path="/pricesetting" element={<PriceSetting />} />
                <Route path="/roundingrule" element={<RoundingRule />} />
                <Route path="/productgroupandrules" element={<ProductGroupAndRules />} />
                <Route path="/siteclusterandrules" element={<SiteClusterAndRules />} />
                <Route path="/termdefinition" element={<TermDefinition />} />
                <Route path="/home" element={<Home />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/" element={<Home />} />
            </Routes>
        </React.Suspense>
    )
}

function PublicRoutes() {
    return <Routes>
        <Route path="/"><Home /></Route>
    </Routes>
}

export default App;
