import React from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import styles from "./NoUserAccess.module.scss";

export const NoUserAccess = () => {

    return (
        <MessageBar
            messageBarType={MessageBarType.severeWarning}
            className={styles.NoAccessDialog}
        >
            <h1>Access Denied.</h1> Unfortunately, this tool is only available to users with specific permissions. <br />Please contact your market super user to request access.
        </MessageBar>
    )
}
