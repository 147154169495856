import * as React from "react";
// import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { initializeLanguage } from "utils/init/i18n";
import SecuredApp from "./SecuredApp";
import { AuthProvider } from "./utils/auth/auth-context"
import App from "./App";
import store from "./store/store";

initializeIcons();
initializeLanguage();

const rootElement = document.getElementById("root") as Element;
const root = createRoot(rootElement);

root.render(
    // <StrictMode>
        <AuthProvider> 
            <Router>
                <SecuredApp>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </SecuredApp>
            </Router>
        </AuthProvider>
    // </StrictMode>
);
