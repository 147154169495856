import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { handleLogOut } from "utils/auth/auth-context";
import { authFetchAsync } from "utils/auth/authFetch";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { useAppDispatch } from "store/typedHooks";
import { setLogout } from "store/features/app/appSlice";
import styles from "./Logout.module.scss";

export function Logout() {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        handleLogOut(); // Revocation Endpoint, Clear Session
        dispatch(setLogout()); // Flag for Inactivity tracker
        logLogout(); // Log the Logout activity

        async function logLogout() {
            try {
                const response = await authFetchAsync("api/Login/userlogout");
                if (!response.ok) {
                    throw new Error("");
                }
            } catch (err) {
                console.log("Failed to log Logout activity");
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleReLogin = () => {
        navigate("/home", { replace: true });
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <span>You have been logged out successfully!</span>
                <PrimaryButton onClick={handleReLogin}>
                    {t("common.login")}
                </PrimaryButton>
            </div>
        </div>
    )
}
