export interface IPaginated<T> {
    /** First record offset. */
    offset: number,
    /** The number of elements contained in the page. */
    count: number
    /** The total number of items. */
    totalCount?: number
    /** The data items. */
    items: Array<T>
    /** Summary or totals row */
    summary?: T
}

export interface IPaginatedWithGroup<T,G = undefined> {
    /** First record offset. */
    offset: number,
    /** The number of elements contained in the page. */
    count: number,
    /** The total number of items. */
    totalCount?: number,
    /** The data items. */
    items: Array<T>,
    /** Summary or totals row */
    summary?: any,
    groups?: Array<G>,
    isExcelEnable?: number,
    approvedCount?: number
}

export interface IPoductsWithGroup<T,G = undefined> {
    items: Array<T>,
    groups?: Array<G>
}

export interface ISimpleResponseWithGroup<T,G = undefined> {
    items: Array<T>,
    groups?: Array<G>
}

export interface IGroups {
    children: IGroups[] | [],
    count: number,
    isCollapsed?: boolean,
    key: string,
    level: number,
    name: string,
    startIndex: number
}

/** AsyncSelect Option */
export type SelectOption = { 
    value: number, 
    label: string 
}

export type DictionaryEntry<KeyType, ValueType> = {
    key: KeyType,
    value: ValueType
}

export const storageKey = {
    PSNumFreezedColumns: "PS-num-freezed-columns",
    PSOrderedColumns: "PS-ordered-columns",
    PSHiddenColumns: "PS-hidden-columns",

    CCNumFreezedColumns: "CC-num-freezed-columns",
    CCOrderedColumns: "CC-ordered-columns",
    CCHiddenColumns: "CC-hidden-columns",

    CRPTSelectedMarket: "CRPT-selected-market"
}
