import dayjs from "dayjs"
import i18n from "i18next";
import { PricingManagerStatus } from "types/lookups";

/* Date formatters */

/**
 * Converts an ISO date string or date object to a local date string.
 * @param {any} date Input date string/date.
 * @param {sring} format Format to output - default is YYYY-MM-DD.
 * @param {boolean} duseFriendlyNamesate Return friendly names if match.
 */
export const formatUtcDate = function (date, format = DateFormatEnum.Date, useFriendlyNames = false) {
   if (!date) {
      return date;
   }

   if (date instanceof Date) {
      return dayjs(date).format(format);
   }

   if (date.startsWith("0")) {
      return null;
   }

   if (useFriendlyNames && new Date().toDateString() === new Date(date).toDateString()) {
      return i18n.t("common:date.today");
   }

   if (!date.endsWith("Z")) {
      date = date + "Z";
   }

   return dayjs(new Date(date)).format(format);
}

/**
 * Parse a date string into a date object.
 * @param {date} date Input date string.
 */
export const parseUtcDate = function (date) {
   if (!date) {
      return date;
   }
   if (date instanceof Date) {
      return date;
   }

   if (!date.endsWith("Z")) {
      date = date + "Z";
   }
   return new Date(date);
}

export const DateFormatEnum = {
   Hour: "HH:mm",
   Date: "YYYY-MM-DD",
   DateTime: "YYYY-MM-DD HH:mm",
   DateFullTime: "YYYY-MM-DD HH:mm:ss"
}

export const truncateLocalDate = function(date) {
   if (!date) {
      return date;
   }
   return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
}

/**
 * Calculate the number of weekdays between two dates.
 */
export const workingDaysBetweenDates = function (d0, d1, holidays = []) {
   var startDate = parseUtcDate(d0);
   var endDate = parseUtcDate(d1);

   // Validate input
   if (endDate < startDate) {
      return 0;
   }

   // Calculate days between dates
   var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
   var diff = endDate - startDate;  // Milliseconds between datetime objects    
   var days = Math.ceil(diff / millisecondsPerDay);

   // Subtract two weekend days for every week in between
   var weeks = Math.floor(days / 7);
   days -= weeks * 2;

   // Handle special cases
   var startDay = startDate.getDay();
   var endDay = endDate.getDay();

   // Remove weekend not previously removed.   
   if (startDay - endDay > 1) {
      days -= 2;
   }
   // Remove start day if span starts on Sunday but ends before Saturday
   if (startDay === 0 && endDay !== 6) {
      days--;
   }
   // Remove end day if span ends on Saturday but starts after Sunday
   if (endDay === 6 && startDay !== 0) {
      days--;
   }
   // Remove holidays
   holidays.forEach(day => {
      if ((day >= d0) && (day <= d1)) {
         /* If it is not saturday (6) or sunday (0), substract it */
         if ((parseUtcDate(day).getDay() % 6) !== 0) {
            days--;
         }
      }
   });
   return days;
}

/* Fixed point number formatters */

export const acceptDecimal = function () {
   return new RegExp(`[\\d${i18n.t("common:settings.decimalseparator")}-]+`, "g")
};
export const cleanDecimal = function (string) {
   return (string.toString().match(acceptDecimal()) || []).join("");
}
export const formatDecimalRoundNormal = function (value, digits = 2, suffix = "") {
   if (value === undefined || value === null || value === "") {
      return value;
   } else {
     const re = new RegExp("^-?\\d+(?:.\\d{0," + (digits || -1) + "})?");
     const newValue = Number(value.toString().match(re)[0]).toFixed(digits);
 
     let [left,right] = newValue.toString().split(".");
 
     left = `${Number.parseFloat(left).toLocaleString("en-GB", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
 
     return left+"."+right;
   }
}
export const formatDecimal = function (value, digits = 2, suffix = "", isRoundNormal = false) {
   if(isRoundNormal) {
      return formatDecimalRoundNormal(value, digits, suffix);
   } else {
      let result = (value === undefined || value === null || value === "") ?
         value :
         `${Number.parseFloat(value).toLocaleString(
            "en-GB",
            {
               minimumFractionDigits: digits,
               maximumFractionDigits: digits
            }
         )}${suffix ? `\u00A0${suffix}` : ""}`;

      if(result === "NaN") {
         result = "";
      }
      return result;
   }
}
export const formatDecimal_2 = function (value, digits = 2, suffix = "") {
   let result = ([undefined, null, ""].includes(value) || Number.isNaN(value)) ?
      "" :
      `${Number.parseFloat(value).toLocaleString(
         "en-GB",
         {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
         }
      )}${suffix ? `\u00A0${suffix}` : ""}`;

   if(result === "NaN") {
      result = "";
   }
   return result;
}
export const formatNumber = function (value) {
   const intVal = Number.parseInt(value,10);
   if(Number.isNaN(intVal)) {
      value = "";
   } else {
      value = intVal.toLocaleString("en-GB");
   }
   return value;
}
export const parseDecimal = function (string) {
   return (string === undefined || string === null || string === "") ? string :
      ((string === "-") ? null : cleanDecimal(string).replace(i18n.t("common:settings.decimalseparator"), "."));
}

/* Bytes size formatters */

export const formatBytes = function (bytes, decimals = 0) {
   if (bytes === 0) {
      return "0 B";
   }

   const k = 1024;
   const dm = decimals < 0 ? 0 : decimals;
   const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

   const i = Math.floor(Math.log(bytes) / Math.log(k));

   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

// const formatDecimalUpDown = (value, decimalPoint, roundingType) => {
//    let [integerPart,decimalPart] = String(value).split(".");
//    decimalPart = (decimalPart || "").padEnd(decimalPoint,"0");

//    if (decimalPart?.length > decimalPoint) {
//       let truncatedDecimal = decimalPart.slice(0,decimalPoint);      

//       if("up" === roundingType) {
//          truncatedDecimal = +truncatedDecimal+1+"";
//          if(truncatedDecimal.length > decimalPoint) {
//             truncatedDecimal = "".padEnd(decimalPoint, 0);
//             integerPart = +integerPart+1+""
//          }
//       }
//       decimalPart = truncatedDecimal;
//    } else {
//       if("up" === roundingType) {
//          if(decimalPoint === "2" && decimalPart[0] === "0") {
//             decimalPart = "0"+(+decimalPart[1]+1)
//          } else if(+decimalPart) {
//             decimalPart = +decimalPart + 1
//          }
//       }
//    }

//    return `${integerPart}${decimalPart?".":""}${decimalPart}`;
// }

const formatDecimalUpDown = (value, decimalPoint, roundingType) => {
   let [integerPart,decimalPart] = String(value).split(".");
   decimalPart = (decimalPart || "").padEnd(decimalPoint,"0");

   let truncatedDecimal = decimalPart.slice(0, decimalPoint);

   if("up" === roundingType && decimalPart.length !== +decimalPoint) {
      if(truncatedDecimal[0] === "0" && decimalPoint === "2") {
         truncatedDecimal = "0"+(+truncatedDecimal[1] + 1);
      } else {
         truncatedDecimal = +truncatedDecimal + 1 + "";
      }

      if(truncatedDecimal.length > decimalPoint) {
         truncatedDecimal = "".padEnd(decimalPoint, 0);
         integerPart = +integerPart + 1 + "";
      }
   }

   return `${integerPart}${truncatedDecimal ? "." : ""}${truncatedDecimal}`;
};

const formatDecimalWithEndingDigit = (value, arrEndingDigit) => {
   let [integerPart, decimalPart] = String(value).split(".");

   // Return original value if it is an integer or has desired ending digit in decimal part
   if (!decimalPart || arrEndingDigit.includes(decimalPart.slice(-1))) {
      return value;
   }
 
   // Create a list of possible ending digits
   const secondLastDigit = decimalPart.slice(-2, -1);
   let arrNewEndingDigits = [];
   if (secondLastDigit) {
      arrEndingDigit.forEach((endingDigit) => {
         arrNewEndingDigits.push(
            ...[
               "" + secondLastDigit + endingDigit,
               "" + (+secondLastDigit - 1) + endingDigit,
            ]
         );
      });
   } else {
      arrNewEndingDigits = arrEndingDigit;
   }

   // Find idealDecimalPart having less delta
   let idealDiff = 10;
   let idealDecimalPart = decimalPart;
   [...arrNewEndingDigits].sort((a,b)=>(+a)-(+b)).forEach((val) => {
      const diff = Math.abs(decimalPart - val);
      if (diff <= idealDiff) {
         idealDiff = diff;
         idealDecimalPart = val;
      }
   });
 
   return [integerPart, idealDecimalPart].join(".");
};

export const formatWithRoundingRule = (value, roundingRuleConfig) => {
   const { endingDigit, decimalPoint, roundingType } = roundingRuleConfig;
   let finalValue;

   // Rule 1: decimalPoint, roundingType
   if ("regular" === roundingType) {
      finalValue = formatDecimal(value, decimalPoint);
   } else {
      finalValue = formatDecimalUpDown(value, decimalPoint, roundingType);
   }

   // Rule 2: endingDigit
   finalValue = formatDecimalWithEndingDigit(finalValue, endingDigit);

   return finalValue;
}

export const getStrikenValue = (item, rawVal, updatedRawVal, decimalPoint) => {
   if([PricingManagerStatus.Rejected, PricingManagerStatus.NoActionRequired].includes(item.statusId) && Number(item.updatedRRPNew) === 0) {
      return (
         <span></span>
      )
   }

   let val;
   let updatedVal;

   if(!decimalPoint) {
      val = formatNumber(rawVal);
      updatedVal = formatNumber(updatedRawVal);
   } else {
      val = formatDecimal_2(rawVal, Number(decimalPoint));
      updatedVal = formatDecimal_2(updatedRawVal, Number(decimalPoint));
   }

   // eslint-disable-next-line eqeqeq
   const updatedValue = (val === updatedVal) ? "" : updatedVal;
   // eslint-disable-next-line no-extra-boolean-cast
   const originalValue = (updatedValue === "" || val === "") ?
      val : <span>&nbsp;<s>{val}</s></span>;

   return (
      <>
         <span>{updatedValue} </span>
         <span>
            {originalValue}
         </span>
      </>
   )
}
