import React, { createContext, useReducer } from "react";
import { loginService } from "./../../login";
import { getStoredRefreshToken } from "../utils";

type Tokens = {accessToken: string, refreshToken: string}
type Action = {type: "login"} | {type: "setTokens", payload: {accessToken: string,refreshToken: string}};
type Dispatch = (action: Action) => void;
type State = {tokens: Tokens | null};
type AuthProviderProps = {children: React.ReactNode};

const AuthContext = createContext<{state: State; dispatch: Dispatch, handleCode: any, handleManualRefresh: any} | undefined>(undefined);

export const actionTypes = {
  LOGIN: "login",
  SET_TOKENS: "setTokens"
} as const;

const initialState = {tokens: null};

let silentRefreshInterval:ReturnType<typeof setInterval>;

function AuthProvider({children}: AuthProviderProps) {
    function reducer(state: State, action: Action) {
        switch (action.type) {
          case actionTypes.LOGIN: {
            loginService.shellUserLogin();
            return state
          }
          case actionTypes.SET_TOKENS: {
            return {...state, tokens: {...action.payload}}
          }
          default: {
            throw new Error("Unhandled action type")
          }
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    function setTokens(tokens: Tokens) {
      localStorage.setItem("loginPayload", "");
      sessionStorage.setItem("sessionTokens", JSON.stringify(tokens));
      setTimeout(()=>{
        dispatch({
          type: actionTypes.SET_TOKENS,
          payload: tokens
        })
      }, 100);
    }

    function handleSilentRefresh() {
      // Silently refresh the ping token every 15 min
        silentRefreshInterval = setInterval(async () => {
          const refreshToken = getStoredRefreshToken();
          if (refreshToken) {
            const newTokens = await loginService.initiateSilentRefreshOfPingToken(refreshToken);
            if(newTokens) {
              setTokens(newTokens);
            }
          }
        },
        899 * 1000
      );
      //
    }

    async function handleCode(code:string, codeVerifier:string) {
        const tokens: Tokens|null = await loginService.handelUserAfterPINGRedirection(code, codeVerifier);
        if(tokens?.accessToken) {
            setTokens(tokens);
            handleSilentRefresh();
            return true;
        }
        return false;
    }

    async function handleManualRefresh() {
      const tokens: Tokens|undefined = await loginService.initiateSilentRefreshOfPingToken(getStoredRefreshToken()||"");
      if(tokens?.accessToken) {
        setTokens(tokens);
        handleSilentRefresh();
      }
    }

    const value = React.useMemo(()=>{
        return {state, dispatch, handleCode, handleManualRefresh};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(state)]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

function useAuth() {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
      throw new Error("useCount must be used within a CountProvider");
    }
    return context;
}

export function handleLogOut() {
  loginService.shellUserLogout();
  clearInterval(silentRefreshInterval);
}

export {
    AuthProvider, // Wraper component
    useAuth // To consume context data
}
