import { useEffect } from "react";
import useIdle from "@custom-react-hooks/use-idle";
import { useNavigate } from "react-router-dom";

const duration = 30 * 60 * 1000; // 30 minutes in milliseconds
// const duration = 1 * 15 * 1000; // 15 sec in milliseconds

const InactivityTracker = () => {
  const navigate = useNavigate();
  const isIdle = useIdle(duration); 

  useEffect(() => {
    if (isIdle) {
      navigate("/logout", { replace: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle]);

  return null;
};

export default InactivityTracker;
