import { useEffect, useState, useRef } from "react";
import jwtDecode from "jwt-decode";
import { getStoredAccessToken } from "utils/utils";
import { useTranslation } from "react-i18next";
import { Persona, PersonaSize, PersonaPresence } from "@fluentui/react/lib/Persona";
import styles from "./UserMenu.module.scss";


import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown"; // IDropdownProps, DropdownMenuItemType
import { Icon } from "@fluentui/react/lib/Icon";

import { ContextualMenu, IContextualMenuItem } from "@fluentui/react/lib/ContextualMenu";
import { useNavigate } from "react-router-dom";

import { saveMarketSelection } from "store/features/multiMarket/multiMarketSlice";
import { useAppSelector, useAppDispatch } from "store/typedHooks";

type DecodedJWT = {
    [key: string]: any;
    givenName: string;
};

const iconStyles = { marginRight: "8px" };

const onRenderOption: any = (option: IDropdownOption): JSX.Element => {
    return (
      <div>
        {option.data && option.data.icon && (
          <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
        )}
        <span>{option.text}</span>
      </div>
    );
};
  
const onRenderTitle: any = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];
  
    return (
      <div>
        {option.data && option.data.icon && (
          <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
        )}
        <span>{option.text}</span>
      </div>
    );
};
  
const onRenderCaretDown = (): JSX.Element => {
    return <Icon iconName="Globe" />;
};
  
const dropdownStyles = { 
    callout: {
        button: {
            span: {
                paddingLeft: 3
            }
        }
    },
    dropdown: { width: 60, marginRight: 20 }
};

let presence = true;

const UserMenu: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [showContextMenu, setShowContextMenu] = useState<Boolean>(false);
    const personaRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const multiMarket = useAppSelector(state => state.multiMarket);
    let marketOptions: IDropdownOption[] = multiMarket.marketData.markets.map((marketname) => {
        return {
            key: marketname,
            text: marketname
        }
    })
    
    // marketOptions = [
    //     // ...marketOptions,

    //     // ---Temp ---
    //     { key: "NL", text: "NL" },
    //     { key: "PL", text: "PL", disabled: true },
    //     { key: "LU", text: "LU", disabled: true },
    //     // ------------

    //     { key: "BE", text: "BE", disabled: true },
    //     { key: "FR", text: "FR", disabled: true },
    //     { key: "UK", text: "UK", disabled: true }
    // ];

    function handleLogout() {
        presence = false;
        navigate("/logout", { replace: true });
    }
    
    useEffect(() => {
        var token = getStoredAccessToken();
        if (token) {
            var decoded:DecodedJWT = jwtDecode(token);
            const givenName= decoded?.givenName;
            setName(givenName || "");
        }
    }, []);

    const menuItems: IContextualMenuItem[] = [
        {
            key: "logout",
            text:  t("common.logout")||"",
            onClick: handleLogout
        }
    ];

    function getInitials(name?: string) {
        if (!name) {
            return "";
        }

        name = name.trim();
        return (name.indexOf(" ") < 0) ?
            name.substring(0, 2).toUpperCase() :
            name.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0].toUpperCase() : null).join("");
    }

    function onPersonaClick() {
        setShowContextMenu(!showContextMenu);
    }

    function onHideContextualMenu() {
        setShowContextMenu(false);
    }

    function onChangeMarket(event: React.FormEvent<HTMLDivElement>, option: any) {
        // option = {"key": "NL","text": "NL"}
        dispatch(saveMarketSelection(option.key));
    }

    return <div className={styles["wrapper"]}>
        {multiMarket.loading && "Loading..."}
        <Dropdown
            onRenderTitle={onRenderTitle}
            onRenderOption={onRenderOption}
            onRenderCaretDown={onRenderCaretDown}
            styles={dropdownStyles}
            options={marketOptions}
            selectedKey={multiMarket.marketData.selectedMarket}
            disabled={multiMarket.loading}
            onChange={onChangeMarket}
            title="Market Selection"
        />

        <Persona
            // componentRef={personaRef}
            ref={personaRef}
            imageUrl={undefined}
            imageInitials={getInitials(name)}
            text={name}
            size={PersonaSize.size32}
            presence={presence ? PersonaPresence.online : PersonaPresence.offline}
            hidePersonaDetails={false}
            imageAlt={name}
            onClick={onPersonaClick}
        />
        <ContextualMenu
            items={menuItems}
            target={personaRef}
            hidden={!showContextMenu}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
        />
    </div>
}

export default UserMenu;
